<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu banner" id="banner">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center sin-padding animated inRight">
      <!-- <div class="swiper-container padding-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background: url(assets/images/equip-solsona-experience.jpg);">

          </div>
        </div> -->
        <!-- Add Pagination -->
        <!-- <div class="swiper-pagination"></div>
      </div> -->

      <div class="row justify-content-center" id="video">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pt-5 pb-5">
          <img src="assets/images/equip-solsona-experience.jpg" alt="Foto de Grup" id="FotoGrup">
        </div>
      </div>

    </div>
  </div>

  <!-- <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center">
      <a class="pt-2 animated inRight subtitol-IV" [routerLink]="['/',currentLang,'missio-visio-valors']" routerLinkActive="active">{{'qui-som.missio-visio-valors' | translate}}</a>
    </div>
  </div> -->

  <div class="row justify-content-center solsona">
    <div class="col-md-8 col-lg-6 col-xl-6 col-xs-10 col-sm-10 col-10 a-center py-5 text-qui-som animated inLeft">
      {{'qui-som.descripcio' | translate}}
    </div>
  </div>

  <!-- Missió i Valors -->
  <div class="row padding-menu titulos sin-margen" id="titulos">
    <div class="col-12 col-md-8 col-lg-8 col-xl-5 mx-auto mt-4">
      <h1 class="objetivos-tl titol-IV">{{'missio-visio-valors.titol' | translate}}</h1>
  
      <h3 class="objetivos animated inRight subtitol-IV">{{'missio-visio-valors.missio.titol' | translate}}</h3>
      <div class="text-missio-valors">
        {{'missio-visio-valors.missio.1' | translate}} <b>{{'missio-visio-valors.missio.ensenyar' | translate}}</b> {{'missio-visio-valors.missio.2' | translate}} <b>{{'missio-visio-valors.missio.descobrir' | translate}}</b>
        {{'missio-visio-valors.missio.3' | translate}} <b>{{'missio-visio-valors.missio.visites-experiencials' | translate}}</b> {{'missio-visio-valors.missio.4' | translate}}.
      </div>
  
      <h3 class="objetivos animated inLeft subtitol-IV">{{'missio-visio-valors.visio.titol' | translate}}</h3>
      <div class="text-missio-valors">{{'missio-visio-valors.visio.1' | translate}} <b>{{'missio-visio-valors.visio.comprenguin' | translate}}</b> {{'missio-visio-valors.visio.i' | translate}} <b>{{'missio-visio-valors.visio.estimin' | translate}}</b>
        {{'missio-visio-valors.visio.2' | translate}} <b>{{'missio-visio-valors.visio.història' | translate}}</b> {{'missio-visio-valors.visio.3' | translate}} <b>{{'missio-visio-valors.visio.territori' | translate}}</b>.</div>
  
      <h3 class="objetivos animated inRight subtitol-IV">{{'missio-visio-valors.valors' | translate}}</h3>
  
      <h4 class="valors animated inRigt">{{'missio-visio-valors.valor.1.titol' | translate}}</h4>
      <div class="text-missio-valors">{{'missio-visio-valors.valor.1.text' | translate}}</div>
  
      <h4 class="valors animated inLeft">{{'missio-visio-valors.valor.2.titol' | translate}}</h4>
      <div class="text-missio-valors">{{'missio-visio-valors.valor.2.text' | translate}}</div>
  
      <h4 class="valors animated inRight">{{'missio-visio-valors.valor.3.titol' | translate}}</h4>
      <div class="text-missio-valors">{{'missio-visio-valors.valor.3.text' | translate}}</div>
  
      <h4 class="valors animated inLeft">{{'missio-visio-valors.valor.4.titol' | translate}}</h4>
      <div class="text-missio-valors">{{'missio-visio-valors.valor.4.text' | translate}}</div>
  
      <h4 class="valors pt-3 animated inRight">{{'missio-visio-valors.valor.5.titol' | translate}}</h4>
      <div class="text-missio-valors">{{'missio-visio-valors.valor.5.text' | translate}}</div>
  
    </div>
  </div>
  <!-- end Missió i Valors -->


  <!-- <div class="row justify-content-center guies" id="guies">
    <div class="col-md-10 col-lg-10 col-xs-12 col-sm-12 col-12 pt-5 pb-5">
      <div class="row text-qui-som">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-5">
          <h1 class="animated inLeft titol-IV-qui-som">{{'qui-som.titolequip' | translate}}</h1>
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-12 guias a-center animated inRight">
          <img src="assets/images/equip1.jpg" class="img-responsive">
          <h3>{{'qui-som.1.nom' | translate}}</h3>
          {{'qui-som.1.text' | translate}}
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-12 guias a-center animated inLeft">
          <img src="assets/images/equip1.jpg" class="img-responsive">
          <h3>{{'qui-som.2.nom' | translate}}</h3>
          {{'qui-som.2.text' | translate}}
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-12 guias a-center animated inRight">
          <img src="assets/images/equip1.jpg" class="img-responsive">
          <h3>{{'qui-som.3.nom' | translate}}</h3>
          {{'qui-som.3.text' | translate}}
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-12 guias a-center animated inLeft">
          <img src="assets/images/equip1.jpg" class="img-responsive">
          <h3>{{'qui-som.4.nom' | translate}}</h3>
          {{'qui-som.4.text' | translate}}
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-12 guias a-center animated inLeft">
          <img src="assets/images/equip1.jpg" class="img-responsive">
          <h3>{{'qui-som.5.nom' | translate}}</h3>
          {{'qui-som.5.text' | translate}}
        </div>
        <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-12 guias a-center animated inLeft">
          <img src="assets/images/equip1.jpg" class="img-responsive">
          <h3>{{'qui-som.6.nom' | translate}}</h3>
          {{'qui-som.6.text' | translate}}
        </div>
      </div>
    </div>
  </div> -->
</div>