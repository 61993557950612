<!-- footer-start -->
<footer class="footer">
  <div class="footer_top">
    <div class="container">
      <!--BLOC A: Solsona Experience (sense logo) + navegació pel web -->
      <!-- BLOC B: Per a més informació  -->
      <!-- BLOC C: planifica la teva estada webs turisme -->
      <!-- BLOC D: segueix-nos, social-links i instagram -->
      <!-- BLOC E: col·laboradors -->
      <!-- BLOC F: patrocinadors -->
      <!-- XL +1200px:
      ROW1 A=4 B+C=4 D=4
      ROW2 E=9 F=3  -->
      <!-- LG +992px: idem -->
      <!-- MD +720px: A+B=12 -->
      <!-- SM +540px:  -->
      <div class="row">
        <div class="offset-lg-1 col-lg-2 offset-xl-1 col-xl-2 amaga-md-sm-xs">
          <div class="footer_widget">
            <div class="footer_logo-IV">
              <a [routerLink]="['/',currentLang,'home']" routerLinkActive="active">
                <img src="assets/logos/logo-solsona-experience-footer.png" alt="">
              </a>
            </div>
            <div class="footer_logo-IV">
              <a target=_blank href="http://act.gencat.cat/afiliacio-a-marques/directori-empreses/entry/10/10244/?gf_search=solsona&parent_lead=10245&filter_147=arts-i-cultura">
                <img src="assets/logos/logo-afiliat-act-footer.png" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-5 offset-xl-1 col-xl-4 col-md-6 amaga-md-sm-xs">
          <div class="footer_widget">
            <div class="row">
              <div class="offset-1 col-11">
                <h3 class="footer_title">
                  Solsona Experience
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="offset-1 col-5">
                <ul class="links">
                  <li><a [routerLink]="['/',currentLang,'visites-programades']" routerLinkActive="active">{{ 'header.visites-programades' | translate}}</a></li>
                  <li><a [routerLink]="['/',currentLang,'experiencies']" routerLinkActive="active">{{ 'header.experiencies' | translate}}</a></li>
                  <li><a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">{{ 'header.grups-particulars' | translate}}</a></li>
                </ul>
              </div>
              <div class="col-5">
                <ul class="links">
                  <li><a [routerLink]="['/',currentLang,'qui-som']" routerLinkActive="active">{{ 'footer.qui-som' | translate}}</a></li>
                  <li><a [routerLink]="['/',currentLang,'avis-legal']" routerLinkActive="active">{{ 'footer.avis-legal' | translate}}</a></li>
                  <li><a [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active">{{ 'header.contacte' | translate}}</a></li>
                </ul>
              </div>
            </div>
            <!-- <div class="row">
              <div class="offset-1 col-11 mt-4">
                <ul class="links">
                  <li><a [routerLink]="['/',currentLang,'blog']" routerLinkActive="active">{{ 'footer.blog' | translate}}</a></li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-lg-4 pr-lg-4 col-xl-3 col-12 mx-auto">
          <div class="footer_widget">
            <div class="row">
              <div class="ml-lg-2 col-lg-12 col-8 mx-auto">
                <h3 class="footer_title footer_title-social">
                  {{ 'footer.segueix-nos' | translate}}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="socail_links">
                  <ul>
                    <li><a target="_blank" href="https://www.instagram.com/solsonaexperience/"> <i class="fab fa-instagram"></i> </a></li>
                    <li><a target="_blank" href="https://twitter.com/solsoexperience"> <i class="fa fa-twitter"></i> </a></li>
                    <li><a target="_blank" href="https://www.tiktok.com/@solsonaexperience?lang=ca"> <i class="fab fa-tiktok"></i> </a></li>
                    <li><a target="_blank" href="https://www.youtube.com/channel/UC-VtOu-FVkRMFJc2TiXC6_Q"> <i class="fab fa-youtube"></i> </a></li>
                    <li><a target="_blank" href="https://www.facebook.com/solsonaexperience/"> <i class="fab fa-facebook"></i> </a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="instafeed" class="mt-3"></div>
          </div>
        </div>
        <div class="offset-3 col-6 offset-sm-3 col-sm-6 offset-md-3 col-md-6 amaga-lg-xl">
          <div class="footer_widget">
            <div class="footer_logo-IV">
              <a [routerLink]="['/',currentLang,'home']" routerLinkActive="active">
                <img src="assets/logos/logo-solsona-experience-footer-HOR.png" alt="">
              </a>
            </div>
            <div class="footer_logo-IV">
              <a target=_blank href="http://act.gencat.cat/afiliacio-a-marques/directori-empreses/entry/8/10245/?gf_search=solsona">
                <img src="assets/logos/logo-afiliat-act-footer.png" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copy-right_text">
    <div class="container">
      <div class="footer_border"></div>
      <div class="row">
        <div class="col-xl-12">
          <p class="copy_right text-center">
          </p>
          <p class="copy_right text-center">
            {{ 'footer.copyright' | translate}} &copy; 2020
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- footer-end -->