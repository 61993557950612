import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private gtmService: GoogleTagManagerService,private router: Router) {
    //captures router events and informs Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview', location.pathname);
      }
    });
    this.router.events.forEach(item => {
  if (item instanceof NavigationEnd) {
    const gtmTag = {
      event: 'page',
      pageName: item.url
    };
    this.gtmService.pushTag(gtmTag);
  }
});
  }



}
