import { Injectable } from '@angular/core';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { LoggerService } from '../logger.service';
import { LocalizationService } from '../localization/localization.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager'


@Injectable({
  providedIn: 'root'
})
export class CounterService {
  currentLang: string;
  userDocumentRef: AngularFirestoreDocument<any>;
  userDocumentRef$: Observable<any>;
  userDocumentRef2: AngularFirestoreDocument<any>;
  userDocumentRef2$: Observable<any>;
  private subscription: Subscription;
  private subscription2: Subscription;


  constructor(
    private gtmService: GoogleTagManagerService,
    private db: AngularFirestore,
    private logger: LoggerService,
    private localization: LocalizationService) {
    this.localization.currentLang$.subscribe(lang => {
      this.currentLang = lang;
    });
  }


  newProduct(type, product) {
    var booking = 0;
    var review = 0;
    var message = 0;
    if (type == "booking") { booking = 1; };
    if (type == "review") { review = 1; };
    if (type == "message") { message = 1; };
    // this.logger.log(booking + "  review " + review + "  message " + message + "TYPE " + type);
    // this.logger.log("Es registrara el producte: " + product + " amb comptador 1 " + type);
    this.db.collection('product').doc(product).set({
      bookings: booking,
      reviews: review,
      messages: message
    })
      .then(() => {
        // this.logger.log("Producte registrat: " + product);
      })
      .catch(function(error) {
        // this.logger.error("Error de Firestore escrivint producte: " + product, error);
      });
  }

  /* Actualitza comptadors de la col·lecció product*/
  updateCounterProduct(type, product, counter) {
    if (type == "booking") {
      this.db.collection('product').doc(product).update({
        bookings: counter + 1,
      })
        .then(() => {
          // this.logger.log("Booking incrementat de " + product + " a " + (counter + 1));
        })
        .catch(function(error) {
          // this.logger.error("Error de Firestore incrementant booking de " + product + ": ", error);
        });
    }
    else if (type == "review") {
      this.db.collection('product').doc(product).update({
        reviews: counter + 1,
      })
        .then(() => {
          // this.logger.log("Review incrementat de " + product + " a " + (counter + 1));
        })
        .catch(function(error) {
          // this.logger.error("Error de Firestore incrementant review de " + product + ": ", error);
        });
    }
    else if (type == "message") {
      this.db.collection('product').doc(product).update({
        messages: counter + 1,
      })
        .then(() => {
          // this.logger.log("Message incrementat de " + product + " a " + (counter + 1));
        })
        .catch(function(error) {
          // this.logger.error("Error de Firestore incrementant message de " + product + ": ", error);
        });
    }
  }



  /* Consulta estat actual counters*/
  getCounterProduct(type, product) {
    this.userDocumentRef = this.db.doc('product/' + product);
    this.userDocumentRef$ = this.userDocumentRef.valueChanges();
    this.subscription = this.userDocumentRef$.subscribe(
      productDoc => {
        if (productDoc) {
          var oldcounter = 0;
          var bookings = productDoc.bookings | 0;
          var reviews = productDoc.reviews | 0;
          var messages = productDoc.messages | 0;
          // this.logger.log("El producte: " + product + " té " + bookings + " reserves i " + reviews + " ressenyes. Té missatges= " + messages);
          switch (type) {
            case "message":
              {
                oldcounter = messages;
                break;
              }
            case "booking":
              {
                oldcounter = bookings;
                break;
              }
            case "review":
              {
                oldcounter = reviews;
                break;
              }
          }
          this.subscription.unsubscribe();
          this.updateCounterProduct(type, product, oldcounter);
        } else {
          // this.logger.log("No s'ha trobat el producte: " + product + " Es crea el nou producte!");
          this.subscription.unsubscribe();
          this.newProduct(type, product);
        }
      }
    );
  }

  /* Consulta estat actual counters*/
  getStatsCounter(type) {
    this.userDocumentRef2 = this.db.doc('stats/global');
    this.userDocumentRef2$ = this.userDocumentRef2.valueChanges();
    this.subscription2 = this.userDocumentRef2$.subscribe(
      globalStats => {
        if (globalStats) {
          var oldbookings = globalStats.bookings | 0;
          var oldreviews = globalStats.reviews | 0;
          var oldmessages = globalStats.messages | 0;
          var oldclicks = globalStats.clicks | 0;
          this.subscription2.unsubscribe();
          this.updateStats(type, oldclicks, oldbookings, oldreviews, oldmessages);
        } else {
          //Ja estan creats, mai entra aquí en teoria
          this.subscription2.unsubscribe();
        }
      }
    );
  }

  /* Actualitza comptadors generals web a stats*/
  updateStats(type, clicks, bookings, reviews, messages) {
    if (type == "message") {
      this.db.collection('stats').doc('global').update({
        clicks: clicks + 1,
        messages: messages + 1
      })
        .then(() => {
          // this.logger.log("Booking incrementat de " + product + " a " + (counter + 1));
        })
        .catch(function(error) {
          // this.logger.error("Error de Firestore incrementant booking de " + product + ": ", error);
        });
    }
    else if (type == "booking") {
      this.db.collection('stats').doc('global').update({
        clicks: clicks + 1,
        bookings: bookings + 1
      })
        .then(() => {
          // this.logger.log("Booking incrementat de " + product + " a " + (counter + 1));
        })
        .catch(function(error) {
          // this.logger.error("Error de Firestore incrementant booking de " + product + ": ", error);
        });
    }
    else if (type == "review") {
      this.db.collection('stats').doc('global').update({
        clicks: clicks + 1,
        reviews: reviews + 1
      })
        .then(() => {
          // this.logger.log("Booking incrementat de " + product + " a " + (counter + 1));
        })
        .catch(function(error) {
          // this.logger.error("Error de Firestore incrementant booking de " + product + ": ", error);
        });
    }

  }

  /* Crea document de la col·lecció clicks i activa un event de Google Tag Manager*/
  createClickTimestamp(type, origin, target, product) {
    const createdAt = new Date();

    //GoogleTagManagerService
    if (type == "message") {
      const gtmTag = {
        event: 'missatge-enviat-form-contacte',
        destination: target,
        language: this.currentLang,
        platform: navigator.platform,
        timestamp: createdAt,
        useragent: navigator.userAgent
      };
      this.gtmService.pushTag(gtmTag);
    }
    else if (type == "booking") {
      const gtmTag = {
        event: 'compra-entrades-solsones',
        product: product,
        origin_href: origin,
        destination: target,
        language: this.currentLang,
        platform: navigator.platform,
        timestamp: createdAt,
        useragent: navigator.userAgent
      };
      this.gtmService.pushTag(gtmTag);
    }
    else if (type == "review") {
      const gtmTag = {
        event: 'new-google-review',
        product: product,
        origin_href: origin,
        destination: target,
        language: this.currentLang,
        platform: navigator.platform,
        timestamp: createdAt,
        useragent: navigator.userAgent
      };
      this.gtmService.pushTag(gtmTag);
    }

    //FireStore
    this.db.collection('clicks').doc(createdAt.toString()).set({
      destination: target,
      href: origin,
      type: type,
      product: product,
      language: this.currentLang,
      platform: navigator.platform,
      timestamp: createdAt,
      useragent: navigator.userAgent
    })
      .then(() => {
        // this.logger.log("Click registrat de tipus: " + type + " al producte: " + product);
        this.getCounterProduct(type, product);
        this.getStatsCounter(type);
      })
      .catch(function(error) {
        //  console.error("Error de Firestore registrant click de tipus: " + type + " al producte: " + product + ": ", error);
      });;
  }
}
