import { Component, OnInit, Renderer2 } from '@angular/core';
import { LocalizationService } from '../../services/localization/localization.service'
import { SeoService } from '../../services/seo/seo.service'
import { LoggerService } from '../../services/logger.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';

/* Plantilla diferent a un mòdul normal. COMPTE!*/
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  //Títol autodescriptiu del component, per millorar SEO
  //S'obté del json en format i18n corresponent
  newTitle = marker('components.404.title');
  private language: string;
  private urlLanguage: string;
  currentLang: string;


  constructor(
    private localization: LocalizationService,
    private seo: SeoService,
    private logger: LoggerService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router) {
    // Aquí el component ha de definir la seva URL canònica dinàmicament segons llengua i producte
    this.localization.currentLang$.subscribe(lang => {
      this.currentLang = lang;
    });

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    /*Carrega l'idioma preferent del navegador, independentment de l'idioma
    de la pàgina d'on provingui el client*/
    this.language = this.localization.getBrowserLang();
    this.getUrlLang();
    if (this.urlLanguage == this.language) {
      this.localization.useLanguage(this.urlLanguage);
      this.localization.setTitle(this.newTitle);
      this.setHtmlLang(this.urlLanguage);
      // Aquí el component ha de definir la seva URL canònica dinàmicament segons llengua i producte
      this.localization.currentLang$.subscribe(lang => {
        this.currentLang = lang;
      });
    } else {
      this.localization.useLanguage(this.language);
      this.localization.setTitle(this.newTitle);
      this.setHtmlLang(this.language);

    }

  }


  /* Extreu la llengua demanada via url
  www.domain.com/:lang/....*/
  getUrlLang() {
    if (this.route.snapshot.paramMap.get('lang') == null) {
      this.urlLanguage = this.localization.defaultLang;
      //  this.logger.log("default " + this.urlLanguage);

    } else {
      this.urlLanguage = this.route.snapshot.paramMap.get('lang');
      //  this.logger.log("llengua extreta de url demanada " + this.urlLanguage);
    }
  }
  /* Canvia <html lang="ca">
  ca,es,en,fr*/
  setHtmlLang(chosenLanguage) {
    //  this.logger.log("canviarem idioma tag HTML a " + chosenLanguage);
    this.renderer.setAttribute(document.querySelector('html'), 'lang', chosenLanguage);
  }

  ngOnInit(): void {
    //  Redirecció automàtica a home en 10s
    setTimeout(() => {
      if (this.currentLang == 'ca') {
        this.router.navigate(['home']);
      } else {
        this.router.navigate([this.currentLang + '/' + 'home']);
      }
    }, 5000);  //5s
  }
}
