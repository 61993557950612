<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="row padding-menu titulos sin-margen" id="titulos">
  <div class="col-12 col-md-9 col-lg-7 col-xl-6 pb-5 mb-5 mx-auto">
    <h1 class="cond-tl titol-IV">{{'avis-legal.titol' | translate}}</h1>
    <div class="prf-cond text-avis-legal"><b>{{'avis-legal.intro' | translate}}</b></div>
    <div class="prf-cond text-avis-legal">{{'avis-legal.gdpr' | translate}}</div>
    <div class="prf-cond text-avis-legal">{{'avis-legal.1' | translate}}</div>
    <div class="prf-cond text-avis-legal">{{'avis-legal.2' | translate}}</div>
    <div class="prf-cond text-avis-legal">{{'avis-legal.3' | translate}}</div>
    <div class="prf-cond text-avis-legal">{{'avis-legal.4' | translate}}</div>
    <div class="prf-cond text-avis-legal">{{'avis-legal.5' | translate}}</div>

  </div>
</div>