import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../services/localization/localization.service'



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentLang: string;


  constructor(
    private localization: LocalizationService) {
    this.localization.currentLang$.subscribe(lang => {
      this.currentLang = lang;
    });
  }

  ngOnInit(): void {

  }

}
