import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from '../../services/localization/localization.service'
import { CounterService } from '../../services/firestore/counter.service'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentLang: string;

  /* Activa el servei que registra clicks per reviews o booking*/
  clickTimestamp(type, target) {
    var origin = "WHATSAPP";
    this.counter.createClickTimestamp(type, origin, target, 'header');
  }


  constructor(
    private renderer: Renderer2,
    private localization: LocalizationService,
    private counter: CounterService) {
    this.localization.currentLang$.subscribe(lang => {
      this.currentLang = lang;
    });
  }

  /* Canvia llengua per acció d'usuari*/
  changeLang(chosenLanguage) {
    this.localization.useLanguage(chosenLanguage);
    this.setHtmlLang(chosenLanguage);
  }

  /* Canvia <html lang="ca">
  ca,es,en,fr*/
  setHtmlLang(chosenLanguage) {
    //  this.logger.log("canviarem idioma tag HTML a " + chosenLanguage);
    this.renderer.setAttribute(document.querySelector('html'), 'lang', chosenLanguage);
  }

  ngOnInit(): void {
  }

}
