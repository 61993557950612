<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'visites-programades.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'visites-programades.subtitol' | translate}}</p>
    </div>
  </div>

  <!-- VISITES -->
  <section class="row section-visites" id="section-visites">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">

      <div class="row flex-wrap">
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            <p>{{'visites.solsona-monumental.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <label class="reserva">{{'visites.solsona-monumental.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="Gegants bojos">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
            <p>{{'visites.gegants-carnaval-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.gegants-carnaval-solsona.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.olius.titol' | translate}}</h3>
            <p>{{'visites.olius.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <label class="reserva">{{'visites.olius.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/santuari-miracle-mini.jpg" alt="santuari del miracle">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.santuari-miracle.titol' | translate}}</h3>
            <p>{{'visites.santuari-miracle.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <label class="reserva">{{'visites.santuari-miracle.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/santuari-pinos-torre-ardevol-mini.jpg" alt="Santuari de Pinos i torre ardevol">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.santuari-pinos-torre-ardevol.titol' | translate}}</h3>
            <!--<p>{{'visites.santuari-pinos-torre-ardevol.subtitol' | translate}}</p>-->
          </div>
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <label class="reserva">{{'visites.santuari-pinos-torre-ardevol.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'torre-vallferosa']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/torre-vallferosa-mini.jpg" alt="Torre de Vallferosa">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.torre-vallferosa.titol' | translate}}</h3>
            <!--<p>{{'visites.torre-vallferosa.subtitol' | translate}}</p>-->
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'torre-vallferosa']" routerLinkActive="active">
            <label class="reserva">{{'visites.torre-vallferosa.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="pou gel Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            <p>{{'visites.pou-gel.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <label class="reserva">{{'visites.pou-gel.button' | translate}}</label>
          </a>
        </div>
      </div>
    </div>
  </section>
  <!-- end visites -->

  <!-- També et pot interessar -->
  <!-- <section class="row section-grups" id="section-grups">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'experiencies.tambeinteressar' | translate}}</h2>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.catedral.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/catedral-mini.jpg" alt="Catedral de Solsona">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.nucli-antic.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/nucli-antic-mini.jpg" alt="necropolis">
          </a></div>
      </div>
    </div>
  </section> -->
  <!-- end et pot interessar -->


</div>