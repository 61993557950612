import { Component, OnInit, Renderer2 } from '@angular/core';
import { LocalizationService } from '../../services/localization/localization.service'
import { SeoService } from '../../services/seo/seo.service'
import { LoggerService } from '../../services/logger.service';
import { CounterService } from '../../services/firestore/counter.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-a-la-carta',
  templateUrl: './a-la-carta.component.html',
  styleUrls: ['./a-la-carta.component.css']
})
export class ALaCartaComponent implements OnInit {
  canonicalLink: string;
  caLink: string;
  esLink: string;
  enLink: string;
  frLink: string;
  currentLang: string;
  private urlLanguage: string;


  /*Títol, descripció, keywords i meta autodescriptiu del component, per millorar SEO.
  S'obté del json en format i18n corresponent*/
  newTitle = marker('components.a-la-carta.title');
  metaContentDescription = marker('components.a-la-carta.description');
  metaContentKeywords = marker('components.a-la-carta.keywords');
  ogLocale = marker('components.ogLocale');
  ogSiteName = marker('components.ogSiteName');

  /* Activa el servei que registra clicks per reviews o booking*/
  clickTimestamp(type, target) {
    var origin = this.canonicalLink;
    this.counter.createClickTimestamp(type, origin, target, 'a-la-carta');
  }

  constructor(
    private localization: LocalizationService,
    private seo: SeoService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private counter: CounterService) {
    this.localization.currentLang$.subscribe(lang => {
      this.currentLang = lang;
    });

    this.logger.initSwipeable();

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    /* carrega el canonicallink del servei SEO,
    i pren el valor inicial document.location.href,
    i escolta canvis*/
    this.seo.getCanonicalLink();
    this.canonicalLink = document.location.href;
    this.seo.canonicalLink$.subscribe(link => {
      this.canonicalLink = link;
    });

    /*Llegeix idioma de la url, si n'hi ha, i activa ngx-translate
    També canvi html lang*/
    this.getUrlLang();
    this.localization.useLanguage(this.urlLanguage);
    this.setHtmlLang(this.urlLanguage);

    /*Canvia títol cada cop que hi ha canvi de component,
    o canvi d'idioma*/
    this.localization.setTitle(this.newTitle);


    /*Prepara href links per facilitar navegació SEO*/
    this.createHrefLinks();
    /*  this.updateMetaTags();
    Implementat amb bloc html que cal copiar a tots els components*/
  }


  /* Extreu la llengua demanada via url. No es pot passar al servei
  No llegeix bé la ruta des d'allà
  www.domain.com/:lang/....*/
  getUrlLang() {
    if (this.route.snapshot.paramMap.get('lang') == null) {
      this.urlLanguage = this.localization.defaultLang;

    } else {
      this.urlLanguage = this.route.snapshot.paramMap.get('lang');
    }
  }

  /* Canvia <html lang="ca">
  ca,es,en,fr*/
  setHtmlLang(chosenLanguage) {
    //  this.logger.log("canviarem idioma tag HTML a " + chosenLanguage);
    this.renderer.setAttribute(document.querySelector('html'), 'lang', chosenLanguage);
  }

  /* Crea atributs html link alternate - SEO
  <link rel="alternate" appMoveToHead href="https://solsonaexperience.com/home" hreflang="ca" />
  <link rel="alternate" appMoveToHead href="https://solsonaexperience.com/es/home" hreflang="es" />
  <link rel="alternate" appMoveToHead href="https://solsonaexperience.com/en/home" hreflang="en" />
  <link rel="alternate" appMoveToHead href="https://solsonaexperience.com/fr/home" hreflang="fr" />
  <link rel="alternate" appMoveToHead href="https://solsonaexperience.com/home" hreflang="x-default" />
  */
  createHrefLinks() {
    var path = this.localization.getNoLangPathname();
    //  this.logger.log(path);
    this.caLink = "https://solsonaexperience.com" + path;
    this.esLink = "https://solsonaexperience.com/es" + path;
    this.enLink = "https://solsonaexperience.com/en" + path;
    this.frLink = "https://solsonaexperience.com/fr" + path;
  }

  /* META TAGS IMPLEMENTAT des del .html amb una directiva appMoveToHead
  index.html defineix metatags:
  description
  keywords
  Twitter?
  Facebook?
  I aquesta funció els va actualitzant segons info del component
  */
  /*updateMetaTags() {
    this.seo.addMetaTag("keywords", this.metaContentKeywords);
    this.seo.addMetaTag("description", this.metaContentDescription);

  }*/



  ngOnInit(): void {

  }
}
