<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'experiencies.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'experiencies.subtitol' | translate}}</p>
    </div>
  </div>


  <!-- EXPERIENCES -->
  <section class="row section-visites" id="section-visites">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 mobile-card-container">

      <div class="row flex-wrap">
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/tasta-solsona-mini.jpg" alt="Tasta Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.tasta-solsona.titol' | translate}}</h3>
            <p>{{'visites.tasta-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.tasta-solsona.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/campanar-mini.jpg" alt="Campanar de la catedral">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.campanar.titol' | translate}}</h3>
            <p>{{'visites.campanar.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
            <label class="reserva">{{'visites.campanar.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'a-la-carta']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/a-la-carta-mini.jpg" alt="A la carta">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.a-la-carta.titol' | translate}}</h3>
            <p>{{'visites.a-la-carta.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'a-la-carta']" routerLinkActive="active">
            <label class="reserva">{{'visites.a-la-carta.button' | translate}}</label>
          </a>
        </div>
      </div>
    </div>
  </section>
  <!-- end experiencies -->


</div>