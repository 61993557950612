<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="row padding-menu titulos sin-margen" id="titulos">
  <div class="col-12 col-md-8 col-lg-8 col-xl-5 mx-auto mt-4 pb-5 mb-5">
    <h1 class="objetivos-tl titol-IV pb-5">{{'missio-visio-valors.titol' | translate}}</h1>

    <h3 class="objetivos animated inRight subtitol-IV">{{'missio-visio-valors.missio.titol' | translate}}</h3>
    <div class="text-missio-valors">
      {{'missio-visio-valors.missio.1' | translate}} <b>{{'missio-visio-valors.missio.ensenyar' | translate}}</b> {{'missio-visio-valors.missio.2' | translate}} <b>{{'missio-visio-valors.missio.descobrir' | translate}}</b>
      {{'missio-visio-valors.missio.3' | translate}} <b>{{'missio-visio-valors.missio.visites-experiencials' | translate}}</b> {{'missio-visio-valors.missio.4' | translate}}.
    </div>

    <h3 class="objetivos animated inLeft subtitol-IV">{{'missio-visio-valors.visio.titol' | translate}}</h3>
    <div class="text-missio-valors">{{'missio-visio-valors.visio.1' | translate}} <b>{{'missio-visio-valors.visio.comprenguin' | translate}}</b> {{'missio-visio-valors.visio.i' | translate}} <b>{{'missio-visio-valors.visio.estimin' | translate}}</b>
      {{'missio-visio-valors.visio.2' | translate}} <b>{{'missio-visio-valors.visio.història' | translate}}</b> {{'missio-visio-valors.visio.3' | translate}} <b>{{'missio-visio-valors.visio.territori' | translate}}</b>.</div>

    <h3 class="objetivos animated inRight subtitol-IV pb-5">{{'missio-visio-valors.valors' | translate}}</h3>

    <h4 class="valors animated inRigt">{{'missio-visio-valors.valor.1.titol' | translate}}</h4>
    <div class="text-missio-valors">{{'missio-visio-valors.valor.1.text' | translate}}</div>

    <h4 class="valors animated inLeft">{{'missio-visio-valors.valor.2.titol' | translate}}</h4>
    <div class="text-missio-valors">{{'missio-visio-valors.valor.2.text' | translate}}</div>

    <h4 class="valors animated inRight">{{'missio-visio-valors.valor.3.titol' | translate}}</h4>
    <div class="text-missio-valors">{{'missio-visio-valors.valor.3.text' | translate}}</div>

    <h4 class="valors animated inLeft">{{'missio-visio-valors.valor.4.titol' | translate}}</h4>
    <div class="text-missio-valors">{{'missio-visio-valors.valor.4.text' | translate}}</div>

    <h4 class="valors pt-3 animated inRight">{{'missio-visio-valors.valor.5.titol' | translate}}</h4>
    <div class="text-missio-valors">{{'missio-visio-valors.valor.5.text' | translate}}</div>

  </div>
</div>