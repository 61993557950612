<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">

  <!-- cataleg -->
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'grups-particulars.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'grups-particulars.subtitol' | translate}}</p>
      <a target="_blank" href="{{'visites.cataleg.samara-link' | translate}}"><br><br>
        <label class="reserva">{{'cataleg.button' | translate}}</label></a>
    </div>
  </div>
  <!-- end cataleg -->

  <!-- VISITES A MIDA -->
  <section class="row section-mida" id="section-mida">
    <div class="col-12 col-md-10 offset-md-1 pt-5">
      <div class="row section-pc">
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <h3 class="question">{{ 'home.titols.alacarta' | translate}}</h3>
          <p class="tx-mida">{{ 'home.alacarta.subtitol' | translate}}</p>
        </div>
        <div class="col-12 col-xl-10 offset-xl-1">
          <div class="row">
            <div class="col-12 col-md-4 text-center animated inLeft">
              <img class="icon-3" src="assets/icons/grup.png">
              <p class="prfs-mida">{{ 'home.alacarta.grups' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inTop">
              <img class="icon-3" src="assets/icons/calendari.png">
              <p class="prfs-mida">{{ 'home.alacarta.horaris' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inRight">
              <img class="icon-3" src="assets/icons/smile.png">
              <p class="prfs-mida">{{ 'home.alacarta.guies' | translate}}</p>
            </div>
            <div class="col-12 text-center escriu animated inBottom">
              <a (click)="clickTimestamp('booking', 'https://solsonaexperience/contacte')" [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active"><input class="vst-button" type="button"
                  value="{{ 'home.alacarta.escriu' | translate}}"></a><br><br>
              <b><a [routerLink]="['/',currentLang,'politica']" routerLinkActive="active"> {{'visites.politica.samara' | translate}}</a></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end visites a mida -->

</div>