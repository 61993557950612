<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pb-5">
      <h1 class="objetivos-tl titol-IV">{{'error404' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'pag-no-trobada' | translate}}</p>
    </div>
  </div>
  <div class="col-md-12 col-lg-12 col-12 a-center mb-5 animated inRight">
    <button class="vst-button" style=" width:350px;" [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active">{{'contacta404' | translate}}</button>
  </div>

  <div class="row justify-content-center banner" id="banner">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 sin-padding mx-auto">
      <div class="img-qui-som" style="background: url(assets/images/equip-solsona-experience.jpg);background-size: contain;">
      </div>
    </div>
  </div>


</div>