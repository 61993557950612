<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-8 col-lg-7 col-xl-6 col-xs-10 col-sm-10 col-10 sin-padding pb-5">
      <form class="contactForm" action="" role="form" method="POST">
        <div class="row">
          <div class="col-md-12 col-12 a-center mb-5">
            <h1 class="objetivos-tl titol-IV">{{'contacte.titol' | translate}}</h1>
          </div>
          <div class="col-md-12 col-12 animated inRight">
            <div class="form-group">
              <input class="form-control input-personalizados" id="name" name="name" type="text" placeholder="{{'contacte.nom' | translate}}">
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="form-group">
              <input class="form-control input-personalizados" id="email" name="email" type="text" placeholder="{{'contacte.email' | translate}}" data-rule="email" [title]=" 'contacte.valid.email' | translate">
              <div class="validation"></div>
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="form-group">
              <input class="form-control input-personalizados" id="tlf" name="tlf" type="text" placeholder="{{'contacte.tlf' | translate}}">
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="form-group">
              <input class="form-control input-personalizados" id="activitat" name="activitat" type="text" placeholder="{{'contacte.type' | translate}}">
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="form-group">
              <input class="form-control input-personalizados" id="date" name="date" type="text" placeholder="{{'contacte.data' | translate}}">
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="form-group">
              <input class="form-control input-personalizados" id="pax" name="pax" type="text" placeholder="{{'contacte.pax' | translate}}">
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="form-group">
              <textarea class="form-control input-personalizados" id="msg" name="msg" rows="6" placeholder="{{'contacte.missatge' | translate}}" data-rule="required" [title]=" 'contacte.valid.message' | translate"></textarea>
              <div class="validation"></div>
            </div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="warning-contacte ml-2">{{'contacte.mesinfo' | translate}}
              <a class="vst-exp" [routerLink]="['/',currentLang,'politica']" routerLinkActive="active"> {{'contacte.politica' | translate}}</a></div>
          </div>
          <div class="col-md-12 col-12 col-lg-12 animated inRight">
            <div class="warning-contacte ml-2">{{'contacte.trucans' | translate}}
              <a class="vst-exp" href="tel:663668184" routerLinkActive="active"> {{'contacte.telefon' | translate}}</a></div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div id="sendmessage">{{ 'contacte.messagesent' | translate}}</div>
                <div id="errormessage">{{ 'contacte.messageerror' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-12 mt-5 a-center mb-5 animated inRight">
            <button class="vst-button" (click)="clickTimestamp('message', 'formweb@solsonaexeperience.com')" type="submit">{{'contacte.enviar' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>