<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">

  <!-- SLIDER PRINCIPAL -->
  <div class="row justify-content-center padding-menu banner" id="banner">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center sin-padding animated inRight">
      <div class="swiper-container-fade padding-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide active" style="background: url(assets/images/marato.jpg);">

          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
  <!-- end slider principal -->

  <!-- TÍTOL PRODUCTE -->
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pt-5 pb-5">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'visites.marato.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'visites.marato.subtitol' | translate}}</p>
    </div>
  </div>
  <!-- end títol -->

  <!-- DESCRIPCIÓ PRODUCTE -->
  <div class="row justify-content-center reserves" id="reserves">
    <div class="col-md-10 col-lg-10 col-xs-12 col-sm-12 col-12 sin-padding mt-3 pt-5 pb-5 ">
      <div class="row sin-margen">
        <div class="col-md-6 col-lg-6 col-xs-10 col-sm-10 col-10 animated inLeft pb-5 mx-auto">
          <div class="pt-2">{{'visites.marato.intro' | translate}}</div>
          <br>
          <div class="pt-2 subtitol-IV">{{'visites.marato.1.titol' | translate}}</div>
          <div class="pb-2">{{'visites.marato.1.descripcio' | translate}}</div>
          <br>
          <div class="pt-2 subtitol-IV">{{'visites.marato.2.titol' | translate}}</div>
          <div class="pb-2">{{'visites.marato.2.descripcio' | translate}}</div>
          <br>
          <div class="pt-2">{{'visites.marato.compromis' | translate}}</div>
        </div>
        <div class="col-md-6 col-lg-6 col-xs-10 col-sm-10 col-10 a-center animated inRight pb-5 mx-auto">
          <b>{{'visites.durada' | translate}}:</b> {{'visites.marato.durada' | translate}}<br>
          <!-- <b>{{'visites.tipus' | translate}}:</b> {{'visites.marato.tipus' | translate}}<br> -->
          <b>{{'visites.preu' | translate}}:</b> {{'visites.marato.preu' | translate}}<br>
          <b>L'import íntegre es destina a la Marató per la COVID-19</b><br>
          <!-- <b>{{'visites.disponibilitat' | translate}}:</b> {{'visites.marato.disponibilitat' | translate}}<br> -->
          <br>
          <!-- <b>{{'visites.sortida' | translate}}:</b><a target=_blank href="{{'visites.marato.sortida-link' | translate}}"> {{'visites.marato.sortida' | translate}}</a><br> -->
          <b>{{'visites.aforament' | translate}}:</b> {{'visites.marato.aforament' | translate}}<br>

          <!-- <b>{{'visites.dificultat' | translate}}:</b> {{'visites.marato.dificultat' | translate}}<br> -->
          <!-- <br>
          <b>{{'visites.organitza' | translate}}:</b> {{'visites.marato.organitza' | translate}}<br>
        -->
          <br>
          <a target="_blank" (click)="clickTimestamp('booking', 'http://entrades.turismesolsones.com/#cat25')" href="http://entrades.turismesolsones.com/#cat25"><label class="reserva">{{'visites.compra' | translate}}</label></a><br><br>
          <a class="vst-opc" [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active">{{'visites.mesinfo' | translate}}</a><br>
        </div>
      </div>
    </div>
  </div>
  <!-- end descripció produtcte -->

</div>
