import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../logger.service';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { SeoService } from '../seo/seo.service'



@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  availableLang = ['ca', 'es', 'en', 'fr'];
  defaultLang: string;
  currentLang = new Subject<string>();
  currentLang$ = this.currentLang.asObservable();
  private pathname: string;
  private noLangPathname: string;
  private modifiedPathname: string;

  constructor(
    private translate: TranslateService,
    private logger: LoggerService,
    private seo: SeoService,
    private location: Location,
    private titleService: Title) {
    this.defaultLang = this.translate.getDefaultLang();
  }

  getBrowserLang() {
    return this.translate.getBrowserLang();
  }

  getTranslate(key) {
    this.translate.get(key).subscribe((text: string) => {
      //this.logger.log(text);
      return text;
    });
  }

  setTitle(newTitle) {
    this.translate.stream(newTitle).subscribe((text: string) => {
      if (text) {
        this.titleService.setTitle(text);
      }
    });
  }

  getPathname() {
    return window.location.pathname;
  }

  getNoLangPathname() {
    this.pathname = this.getPathname();
    if (this.pathname.substring(0, 1) == "/" && this.pathname.substring(3, 4) == "/") {
      return this.pathname.substring(3, this.pathname.length);
    } else {
      return this.pathname.substring(10, this.pathname.length);
    }
  }

  /* Adapta la url mostrada a l'idioma seleccionat, tenint en compte que
  les pàgines en català no mostren el /ca/ abans del pathname*/
  setUrlLang(chosenLanguage) {
    if (this.availableLang.indexOf(chosenLanguage) > -1) {
      //  this.logger.log("canviarem idioma URL a " + chosenLanguage);
      this.pathname = this.getPathname();
      //  this.logger.log(this.pathname.substring(0, 1) + "xx" + this.pathname.substring(3, 4));
      if (this.pathname.substring(0, 1) == "/" && this.pathname.substring(3, 4) == "/") {
        this.modifiedPathname = this.pathname.substring(3, this.pathname.length);
        if (this.modifiedPathname) {
          //    this.logger.log("hi ha path");
          if (chosenLanguage == 'ca') {
            this.location.replaceState(this.modifiedPathname);
          } else {
            this.location.replaceState('/' + chosenLanguage + this.modifiedPathname);
          }
        } else {
          //    NO ES DONARÀ MAI?
          if (chosenLanguage = 'ca') {
            this.location.replaceState('/home');
          } else {
            this.location.replaceState('/' + chosenLanguage + '/home');
          }
        }
      } else {
        //todo
        this.modifiedPathname = this.pathname.substring(1, this.pathname.length);
        //  this.logger.log("line69: " + this.pathname);
        if (this.modifiedPathname) {
          //    this.logger.log("hi ha path");
          if (chosenLanguage == 'ca') {
            // no fem res
          } else {
            this.location.replaceState('/' + chosenLanguage + '/' + this.modifiedPathname);
          }
        } else {
          //    this.logger.log("empty path");
          if (chosenLanguage = 'ca') {
            this.location.replaceState('/home');
          } else {
            this.location.replaceState('/' + chosenLanguage + '/home');
          }
        }
      }
    } else {
      //  this.logger.log("no fem res");
    }
    //Activa servei SEO per a què actualitzi canonical link
    this.seo.getCanonicalLink();
  }

  useLanguage(chosenLanguage) {
    //  this.logger.log("entro a canviar idioma cap a: " + chosenLanguage);
    if (this.availableLang.indexOf(chosenLanguage) > -1) {
      this.translate.use(chosenLanguage);
      this.setUrlLang(chosenLanguage);
      this.currentLang.next(chosenLanguage);
    } else {
      this.translate.use(this.defaultLang);
      this.setUrlLang(this.defaultLang);
      this.currentLang.next(this.defaultLang);
    }
  }
}
