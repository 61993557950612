import { Injectable } from '@angular/core';
import { LoggerService } from '../logger.service';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs';

/*Referència com es construeix el Meta tag*/
type MetaDefinition = {
  charset?: string;
  content?: string;
  httpEquiv?: string;
  id?: string;
  itemprop?: string;
  name?: string;
  property?: string;
  scheme?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private metaTag: MetaDefinition;
  canonicalLink = new Subject<string>();
  canonicalLink$ = this.canonicalLink.asObservable();

  constructor(
    private logger: LoggerService,
    private metaService: Meta) {
    this.canonicalLink.next(document.location.href);
  }

  getCanonicalLink() {
    this.canonicalLink.next(document.location.href);
  }

  addMetaTag(name, content) {
    this.metaService.addTag({ name: name, content: content });
  }

  updateMetaTag(name, content) {

  }


}
